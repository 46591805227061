import React from 'react';

import Layout from '../../../blocks/Layout';
import SEO from '../../../blocks/SEO';
// import Header from './Header';
import Preview from './Preview';
import Features from './Features';
// import WhatsInside from './WhatsInside';
// import Testimonials from '../LandingPage/Testimonials';

const OptinPage = (props) => {
  const {
    pathContext: {
      optinPage: {
        name,
        ctaUrl,
        title,
        // description,
        // descriptionExt,
        wistiaHash,
        subTitle,
        features,
        // previews,
        // testimonials,
        activeCampaignFormId,
      },
    },
  } = props;

  // const headerProps = { title, description, descriptionExt };
  const previewProps = { title, subTitle, wistiaHash };
  const featuresProps = { subTitle, features, activeCampaignFormId };
  // const insideProps = { title, previews, subTitle };
  // const testimonialsProps = { testimonials };

  return (
    <Layout ctaUrl={ctaUrl}>
      <SEO title={`${name} | Deadline Funnel`} />
      {/* <Header {...headerProps} /> */}
      <Preview {...previewProps} />
      <Features {...featuresProps} />
      {/* <WhatsInside {...insideProps} /> */}
      {/* <Testimonials {...testimonialsProps} /> */}
    </Layout>
  );
};

export default OptinPage;