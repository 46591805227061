import React, { useState, useEffect } from "react";

import axios from "axios";

import styles from "./Optin.module.css";

const Optin = (props) => {
  const { activeCampaignFormId } = props;

  const [emailValue, setEmailValue] = useState("");
  const [inEU, setInEU] = useState(false);
  const [marketingConsentValue, setMarketingConsentValue] = useState(false);
  const [cookieConsentValue, setCookieConsentValue] = useState(false);

  const [validateActivated, setValidateActivated] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [buttonText, setButtonText] = useState("Get started");

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setInEU(data.in_eu);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const goToSignup = (e) => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = `${yyyy}-${mm}-${dd}`;

    const utmContent = sessionStorage.getItem('utm_content') ?? "";
    const utmSource = sessionStorage.getItem('utm_source') ?? "";
    const utmCampaign = sessionStorage.getItem('utm_campaign') ?? "";
    const utmTerm = sessionStorage.getItem('utm_term') ?? "";
    const utmMedium = sessionStorage.getItem('utm_medium') ?? "";

    e.preventDefault();
    setValidateActivated(true);
    if (emailValid) {
      setButtonText("Submitting...");

      // Push event to GTM on form submission
      window.dataLayer = window.dataLayer || [];
      try {
        window.dataLayer.push({
          event: "leadSubscribed",
          "event-type": "SubmitEmail",
          "content-name": "3x3 Signup",
          "content-category": "stealth-selling",
        });
      } catch (err) {
        console.log("GTM not found");
      }

      // Submit AC form
      window.location.href = 
        `https://jackborn.activehosted.com/proc.php?u=${activeCampaignFormId}&f=${activeCampaignFormId}&s=&c=0&m=0&act=sub&v=2&email=${encodeURIComponent(emailValue)}&field[233]=${marketingConsentValue ? today : ""}&field[234]=${cookieConsentValue ? today : ""}&field[235][]=${inEU ? "Yes" : ""}&field[110]=${encodeURIComponent(utmSource)}&field[111]=${encodeURIComponent(utmMedium)}&field[112]=${encodeURIComponent(utmCampaign)}&field[113]=${encodeURIComponent(utmContent)}&field[114]=${encodeURIComponent(utmTerm)}`;
    }
  };

  const validateEmail = (email) => {
    setEmailValue(email);
    setEmailValid(/[^@]+@[^.]+\..+/.test(email));
  };

  return (
    <div className={`${styles.inputContainer} ${
      inEU ? styles.euContainer : ""
    }`}>
      <form className={styles.form} onSubmit={goToSignup}>
        <input
          type="email"
          className={`${styles.input} ${
            !emailValid && validateActivated ? styles.inputWithErrors : ""
          }`}
          placeholder="enter your email"
          onChange={(e) => validateEmail(e.target.value)}
          value={emailValue}
        />
        {inEU && (
          <div className={styles.euConsent}>
            <label>
              <input
                type="checkbox"
                name="marketing-consent"
                checked={marketingConsentValue}
                onChange={(e) =>
                  setMarketingConsentValue(!marketingConsentValue)
                }
              />
              I want to receive marketing emails and special offers
            </label>

            <label>
              <input
                type="checkbox"
                name="cookie-consent"
                checked={cookieConsentValue}
                onChange={(e) => setCookieConsentValue(!cookieConsentValue)}
              />
              I agree to the use of cookies for marketing purposes and to
              deliver a better site experience. Please visit our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.deadlinefunnel.com/cookies"
              >
                Cookies page
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.deadlinefunnel.com/tos"
              >
                Terms of Service and Privacy Policy
              </a>{" "}
              for more information.
            </label>
          </div>
        )}
        <button className={styles.button} type="submit">
          {buttonText}
        </button>
        <p className={styles.unsubscribe}>
          You can unsubscribe at any time by clicking the link in the footer of
          our emails.
        </p>
      </form>
    </div>
  );
};

export default Optin;
