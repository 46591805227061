import React from 'react';
import PropTypes from 'prop-types';

import styles from './Features.module.css';

import Container from '../../../../blocks/Container';
import FullWidthRow from '../../../../blocks/FullWidthRow';
// import Text from '../../../../elements/Text';
import Title from '../../../../elements/Title';
import Optin from '../../../../elements/Optin';

const Features = props => {
  const { subTitle, activeCampaignFormId } = props;

  return (
    <div className={styles.features}>
      <Container>
        <FullWidthRow className={styles.features__mainTitle}>
          <Title>
            {subTitle}
          </Title>
        </FullWidthRow>
        <FullWidthRow className={styles.features__optin}>
          <Optin activeCampaignFormId={activeCampaignFormId} />
        </FullWidthRow>
        <Container type="mobile">
          <div className="row">
            {/* {
              features.map((statement, index) => (
                <div key={index} className={`${styles.features__feature} col-xl-6 col-lg-6 col-12 order-xl-0 order-lg-0 order-0`}>
                  <Title type="small" className={`${styles.features__title}`}>{statement.title}</Title>
                  <Text className={`${styles.features__desc}`}>
                    {statement.description}
                  </Text>
                </div>
              ))
            } */}
          </div>
        </Container>
      </Container>
    </div>
  )
}

Features.propTypes = {
  subTitle: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired
}

export default Features