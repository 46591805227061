import React from 'react';

import styles from './Preview.module.css';

import Title from '../../../../elements/Title';
import Container from '../../../../blocks/Container';
import WistiaEmbed from '../../../../elements/WistiaEmbed';
import Image from "../../../../elements/Image";
import FullWidthRow from "../../../../blocks/FullWidthRow";

const Preview = props => {
  const { wistiaHash } = props;
  
  return (
    <div className={styles.preview}>
      <FullWidthRow className={`${styles.preview__bluebg}`}>
        <Container>
          <div className="col-12 on-top">
            <Title
              type="black"
              className={`pb-5 pt-5 text-center ${styles.preview__title}`}
            >
              I created this free training to help you tell better stories that
              will capture the attention of your readers...
            </Title>
          </div>
          <div className="col-xl-12 col-lg-12 col-12 order-xl-1 order-lg-0 order-0 d-flex flex-column align-items-md-center h-100">
            <div className={`${styles.preview__video}`}>
              <WistiaEmbed type="inline" hashedId={wistiaHash} />
            </div>
          </div>
        </Container>
        <Image name="squiggle" className={`${styles.preview__squiggle}`} />
        <Image name="planter" className={`${styles.preview__planter}`} />
      </FullWidthRow>
      <div className={styles.preview__clouds} />
    </div>
  );}

export default Preview